import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

import { GetPermissions, GetPlants, GetRoles } from '../store/actions/reference-data.actions';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ReferenceDataState } from '../store/reference-data.state';
import { PlantModel } from '../models/plant.model';
import { PermissionModel } from '../models/permission.model';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { UserRoleModel } from '../models/user-role.model';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataStoreService {
  @Select(ReferenceDataState.plants) plants$: Observable<PlantModel[]>;
  @Select(ReferenceDataState.roles) userRoles$: Observable<UserRoleModel[]>;
  @Select(ReferenceDataState.permissions) permissions$: Observable<PermissionModel[]>;
  @SelectSnapshot(ReferenceDataState.permissionsSnapshot) permissions: PermissionModel[];

  @Dispatch()
  getPlants = () => new GetPlants();

  @Dispatch()
  getRoles = () => new GetRoles();

  @Dispatch()
  getPermissions = () => new GetPermissions();


}

